<script setup>
const props = defineProps({
  group: String,
  hideBorderBottom: Boolean,
  isLiczak: Boolean
})
</script>

<template>
  <div 
    :class="props.hideBorderBottom ? '': 'border-b-gray-400 border-b',
    isLiczak ? 'pl-2 sm:pl-6' : 'mx-4 md:mx-8' " 
    class="grid grid-cols-1 md:grid-cols-3 py-4  last:border-b-0"

  >
    <div class="">{{ group }}</div>
    <div class="md:col-span-2 font-extralight space-y-0.5">
      <slot/>
    </div>
  </div>
</template>
